<template>
  <list-template
    ref="listTemplate"
    :table-config="tableConfig"
    :search-config="searchConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    :isDisabled="{ exportData: isCanDownLoad}"
    @onSearch="onSearch"
    @onReset="onReset"
    @onChangePage="handleCurrentChange"
    @onHandle="handleClick"
    @onBatchApprove="handleExamine"
    @onExportData="download">
  </list-template>
</template>
<script>
import { mapState } from 'vuex'
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  name: 'ExamineVerifyList',
  computed: {
    ...mapState(['page']),
    isCanDownLoad() {
      const arr = Object.keys(this.search)
      const white = ['approval_status', 'step_status', 'target_id']
      const isDisabled = white.every(item => arr.includes(item))
      return !isDisabled
    }
  },
  created() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.getParams()
  },
  activated() {
    this.getData()
  },
  methods: {
    getParams() {
      this.$_axios2.get('/api/student/approve/get-param').then(res => {
        const {
          data: {
            data: { approval_status, grade, school, step_status, target }
          }
        } = res
        this.searchConfig[0].options = step_status
        this.searchConfig[1].options = approval_status
        this.searchConfig[2].options = target
        this.searchConfig[3].options = school
        this.searchConfig[4].options = grade
        if (step_status.length) {
          this.searchConfig[0].default = step_status[0].id
          this.step_status = step_status[0].id
          this.search.step_status = step_status[0].id
          this.$refs.listTemplate.$children.forEach(ele => {
            if(ele.$el.className.indexOf('t-search-block') > -1) {
              ele.setDefaultSearchValue()
            }
          });
        }
        this.loading = false
        this.getData()
      }).catch(()=>this.loading = false)
    },
    getData() {
      this.loading = true
      if (!this.searchConfig[0].options.length) {
        return setTimeout(() => this.loading = false, 100)
      }
      let params = {
        page: this.page,
        ...this.search
      }
      this.$_axios2
        .get('/api/student/approve/index', { params })
        .then(res => {
          const {
            data: {
              data: { list, page }
            }
          } = res
          if (list) {
            this.total = page.total
            this.pageCount = page.last_page
            this.tableData = list
          } else {
            this.tableData = []
            this.$store.commit('setPage', 1)
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.step_status = this.search.step_status
      this.getData()
    },
    onReset() {
      this.search = { step_status: '' }
    },
    onChange(val) {
      this.search = val
    },
    handleClick(row) {
      let path = 'detail'
      if (row.approval_status === 0) {
        path = 'examine'
      }
      this.$router.push(`./${path}?id=${row.id}&step_status=${this.step_status}`)
    },
    handleCurrentChange(val) {
      this.$store.commit('setPage', val)
      this.getData()
    },
    handleExamine() {
      const params = { ...this.search }
      let that = this
      this.$confirm('您将批量通过所有未审核的审批', '批量审核', {
        confirmButtonText: '通过',
        cancelButtonText: '点错了',
        // cancelButtonClass: 'el-button--danger',
        // distinguishCancelAndClose: true,
        type: 'warning',
        beforeClose(action, instance, done) {
          if (instance.confirmButtonLoading) {
            return
          }
          if (action === 'cancel') {
            return done()
          }
          instance.confirmButtonLoading = true
          instance.confirmButtonText = '请求中...'
          that.$_axios2
            .get(`/api/student/approve/batch-step`, { params })
            .then(res => {
              const {
                data: { status }
              } = res
              instance.confirmButtonLoading = false
              instance.confirmButtonText = '通过'
              if (status) return
              that.getData()
              done()
              that.$message.success('批量审核成功！')
            })
            .catch(() => {
              instance.confirmButtonLoading = false
              instance.confirmButtonText = '通过'
              // if (action === 'confirm') {
              //   instance.confirmButtonLoading = false
              //   instance.confirmButtonText = '通过'
              // } else if (action === 'cancel') {
              //   instance.cancelButtonLoading = false
              //   instance.cancelButtonText = '驳回'
              // }
            })
        }
      })
        .then(() => {})
        .catch(action => {})
    },
    download() {
      let params = JSON.parse(JSON.stringify(this.search))
      this.$_axios2.get('/api/student/approve/export', { params, responseType: 'blob' }).then(res => {
        const filename = '学习目标审批' + '.csv'
        const blob = new Blob([res.data])
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob)
        downloadElement.href = href
        downloadElement.download = decodeURIComponent(filename)
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement)
        window.URL.revokeObjectURL(href)
      })
    }
  },
  data() {
    return {
      step_status: '',
      search: {
        step_status: ''
      },
      tableData: [],
      loading: true,
      //总条数
      total: 0,
      pageCount: 0,
      searchConfig: [
        {
          tag: 'select',
          placeholder: '筛选审批步骤',
          prop: 'step_status',
          options: [],
          label: 'name',
          value: 'id',
          // default: 0,
          change: (val, search) => {
            this.search = { ...search }
          }
        },
        {
          tag: 'select',
          placeholder: '筛选审批状态',
          prop: 'approval_status',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = { ...search }
          }
        },
        {
          tag: 'select',
          placeholder: '筛选目标任务名称',
          prop: 'target_id',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = { ...search }
          }
        },
        {
          tag: 'select',
          placeholder: '筛选校区',
          prop: 'school_id',
          options: [],
          label: 'school_name',
          value: 'id',
          change: (val, search) => {
            this.search = { ...search }
            // if (search.grade_id) this.search.grade_id = ''
          }
        },
        {
          tag: 'select',
          placeholder: '筛选年级',
          prop: 'grade_id',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = { ...search }
          }
        },
        {
          tag: 'input',
          prop: 'student_name',
          placeholder: '搜索学生姓名',
          change: (val, search) => {
            this.search = { ...search }
          }
        }
      ],
      tableConfig: [
        {
          prop: 'approval_status_text',
          label: '状态'
        },
        {
          prop: 'student_name',
          label: '学生姓名'
        },
        {
          prop: 'class_name',
          label: '行政班'
        },
        {
          prop: 'target_name',
          label: '目标任务名称'
        },
        {
          prop: 'step_status_text',
          label: '审批步骤'
        },
        {
          prop: 'school_name',
          label: '校区'
        },
        {
          prop: 'grade_name',
          label: '年级'
        },
        {
          prop: 'handle',
          label: '操作',
          width: '160rem',
          handle: true,
          buttons: (row) => {
            return row.approval_status ? [{ type:"view", text:"查看" }] : [{ type:"approve", text:"审批" }]
          }
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.search {
  &-box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20rem;
  }
  &-l {
    flex-shrink: 0;
    margin-right: 60rem;
  }
  &-r {
    flex: 1;
  }
}
</style>
